/**
 * A simple fuzzy-string matching algorithm.
 *
 * Goals:
 * - Be fast.
 * - Don't change the source texts in any way, i.e. work w/o an index.
 *
 * Match rules:
 * - Case insensitive
 * - Ignore whitespace and `-`, `_`
 * - Allow for exactly one "Buchstabendreher"
 * - Using naive stemming.
 * - XML/HTML markup is ignored.
 *
 * Naive stemming:
 * - Match German Umlauts (in fact all diacritics) as their regular vowel counterpart (ü => u)
 *   as well as ü => ue etc.
 * - Match German `ß` as `s` or `ss`
 * - Match `ss` as `s` and vice versa.
 *
 * Implementation:
 * A search term will be converted to a regular expression that is simple and fast.
 * This regular expression might be quite long, but it is expressive, complete and fast.
 */

/**
 * Map all diacritics to their latin counterparts and other stemming mapping like `ü => ue` etc.
 */
const stem_map = new Map<string, string>()
;(() => {
    for (let i = "À".charCodeAt(0); i <= "ž".charCodeAt(0); i++) {
        const c = String.fromCharCode(i)
        stem_map.set(c, c.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
    }
    // Special handling for German Umlauts ...
    stem_map.set("ä", "a|ae")
    stem_map.set("ö", "o|oe")
    stem_map.set("ü", "u|ue")
    stem_map.set("ß", "s|ss")
    // Common cases ...
    stem_map.set("s", "s|ss")
})()
const word_characters = "A-zÀ-ž0-9"
const valid_characters = new RegExp(`[${word_characters}]`)
const gap_expr = `([-_\\s]??|<.*?>)`
let supports_re_look_behind = (() => {
    try {
        new RegExp("(?<!<[^>]*?)")
        return true
    } catch {
        return false
    }
})()

export type Match = {
    indices: [number, number][]
    /**
     * A score between 0 and 1. For now this is really simple, the score will be:
     * - 1 if the match is 100% and matches a full word.
     * - 0.9 if the match is 100% and at the start of a full word.
     * - 0.8 if the match is 100% and within a word.
     * - 0.7 for all the rest.
     */
    score: number
}

function char_as_regex(c: string): string {
    const mapped_c = stem_map.get(c)
    if (mapped_c) {
        return `(${mapped_c}|${escape_regex(c)})`
    }
    return escape_regex(c)
}

function reverse_stemming(s: string): string {
    return s.replace(/ss/gi, "ß").replace(/ue/gi, "ü").replace(/ae/gi, "ä").replace(/oe/gi, "ö")
}

/**
 * Prepare a matcher that can be run against any text.
 *
 * @param opts.almost_exact if `true` then the only exacti matches ignoring the case will be
 *        returned.
 */
export function fuzzy_matcher(
    search_term: string,
    opts: {ignore_xml?: boolean; almost_exact?: boolean} = {},
): (text: string) => Match[] {
    const search_term_lower = search_term.toLowerCase()
    const search_char_exprs = opts.almost_exact
        ? [...search_term_lower].map((x) => escape_regex(x))
        : [...reverse_stemming(search_term_lower)].map((x) => char_as_regex(x))
    // We build a quite expressive but robust, fast and easy to debug regular expression ...
    // 1) Match 100% ...
    let re = `(${search_char_exprs.join("")})`
    if (search_term_lower.length > 2 && search_term_lower.length < 15 && !opts.almost_exact) {
        // 2) Allow for one letter mix-ups ("Buchstabendreher") ...
        for (let i = 1; i < search_term_lower.length - 1; i++) {
            const mixed_search_term =
                search_term_lower.substr(0, i) +
                search_term_lower[i + 1] +
                search_term_lower[i] +
                search_term_lower.substr(i + 2)
            const mixed_search_char_exprs = [...reverse_stemming(mixed_search_term)].map((x) =>
                char_as_regex(x),
            )
            re += `|(${mixed_search_char_exprs.join("")})`
        }
        // 3) Match with at most one character between each search-term-character ...
        re += `|${search_char_exprs.join(gap_expr)}`
    }
    if (opts.ignore_xml) {
        if (opts.almost_exact) {
            re += `|${search_char_exprs.join("(<.*?>)?")}`
        }
        re = `${supports_re_look_behind ? "(?<!<[^>]*?)" : "(<[^>]*)?(>?)"}(${re})`
    }
    const compiled = new RegExp(re, "gimu")
    return (text: string) => {
        const result: Match[] = []
        let match
        for (;;) {
            match = compiled.exec(text)
            if (!match || match[0] === "") {
                break
            }
            if (opts.ignore_xml) {
                if (match && match[2] === ">") {
                    const ignored = match.splice(0, 3)
                    match.index += ignored[1]?.length ?? 0 + 1
                } else if (match[0].startsWith("<")) {
                    break
                }
            }
            // Calculate score ...
            const full_match = match[0].toLowerCase()
            const is_full_match = full_match === search_term_lower
            const match_end_index = match.index + full_match.length - 1
            let score = 1
            if (is_full_match) {
                const at_word_start =
                    match.index === 0 || !text[match.index - 1].match(valid_characters)
                const partial_match =
                    search_char_exprs.length > 1 &&
                    match_end_index < text.length - 1 &&
                    text[match_end_index + 1].match(valid_characters)
                if (partial_match) {
                    score = at_word_start ? 0.9 : 0.8
                }
            } else {
                score = 0.7
            }
            // Build highlighting indices that ignores HTML tags within the match ...
            const markup_split = full_match.split(/[<>]/g)
            const indices: [number, number][] = []
            let start_index = match.index
            for (let i = 0; i < markup_split.length; i += 2) {
                indices.push([start_index, start_index + markup_split[i].length - 1])
                if (i === markup_split.length - 1) {
                    break
                }
                start_index += markup_split[i].length + markup_split[i + 1].length + 2
            }
            result.push({score, indices})
        }
        return result
    }
}

function escape_regex(s: string) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export function __test_only__set_supports_re_look_behind(b: boolean) {
    supports_re_look_behind = b
}
